// ⬇️ Define here your fonts according to the font file name and format
const allFonts = [
  {
    name: 'AvenirNextCyr-Regular',
    fontFamily: 'Avenir-Regular',
    type: 'woff',
  },
  {
    name: 'AvenirNextCyr-Demi',
    fontFamily: 'Avenir-Demi',
    type: 'woff',
  },
  {
    name: 'AvenirNextCyr-Bold',
    fontFamily: 'Avenir-Bold',
    type: 'woff',
  },
  {
    name: 'Avenir-Book',
    type: 'woff',
  },
  {
    name: 'Avenir-Heavy',
    type: 'woff',
  },
  {
    name: 'Avenir-Roman',
    type: 'woff',
  },
  {
    name: 'Avenir-Black',
    type: 'woff',
  },
  {
    name: 'Gotham-Light',
    type: 'woff',
  },
  {
    name: 'Gotham-Medium',
    type: 'woff',
  },
  {
    name: 'Gotham-Bold',
    type: 'woff',
  },
  {
    name: 'Din-Light',
    type: 'woff',
  },
  {
    name: 'Din-Regular',
    type: 'woff',
  },
  {
    name: 'Din-Bold',
    type: 'woff',
  },
  {
    name: 'Roboto-Bold',
    type: 'woff',
  },
  {
    name: 'Roboto-Regular',
    type: 'woff',
  },
  {
    name: 'Roboto-Light',
    type: 'woff',
  },
  {
    name: 'Montserrat-Regular',
    type: 'woff',
  },
  {
    name: 'Montserrat-Light',
    type: 'woff',
  },
  {
    name: 'Montserrat-Bold',
    type: 'woff',
  },
  {
    name: 'OpenSans-Regular',
    type: 'woff',
  },
  {
    name: 'OpenSans-Light',
    type: 'woff',
  },
  {
    name: 'OpenSans-Bold',
    type: 'woff',
  },
  {
    name: 'Alpine-Regular',
    type: 'woff',
  },
  {
    name: 'Alpine-Light',
    type: 'woff',
  },
  {
    name: 'Alpine-Bold',
    type: 'woff',
  },
  {
    name: 'NuffieldSans-Regular',
    type: 'woff',
  },
  {
    name: 'NuffieldSans-Light',
    type: 'woff',
  },
  {
    name: 'NuffieldSans-Bold',
    type: 'woff',
  },
  {
    name: 'GE-SS-TV-Medium',
    type: 'woff',
  },
  {
    name: 'GE-SS-TV-Bold',
    type: 'woff',
  },
  {
    name: 'Arial',
    type: 'woff',
  },
  {
    name: 'Arial-Bold',
    type: 'woff',
  },
  {
    name: 'Bahij_TheSansArabic-Bold',
    type: 'woff',
  },
  {
    name: 'Bahij_TheSansArabic-ExtraBold',
    type: 'woff',
  },
  {
    name: 'SaudiaText-LightItalic',
    type: 'woff',
  },
  {
    name: 'TheSansArabic-Light',
    type: 'woff',
  },
  {
    name: 'Amasis-MT-Std',
    type: 'woff',
  },
  {
    name: 'AmsiProCond-Black',
    type: 'woff',
  },
  {
    name: 'LandRoverWeb-Bold',
    type: 'woff',
  },
  {
    name: 'LandRoverWeb-Demi',
    type: 'woff',
  },
  {
    name: 'LandRoverWeb-Light',
    type: 'woff',
  },
  {
    name: 'LandRoverWeb-Medium',
    type: 'woff',
  },
  {
    name: 'LandRoverWide-Bold',
    type: 'woff',
  },
  {
    name: 'LandRoverWide-Demi',
    type: 'woff',
  },
  {
    name: 'LandRoverWide-Light',
    type: 'woff',
  },
  {
    name: 'LandRoverWide-Medium',
    type: 'woff',
  },
  {
    name: 'Poppins-Black',
    type: 'woff',
  },
  {
    name: 'Poppins-BlackItalic',
    type: 'woff',
  },
  {
    name: 'Poppins-BoldItalic',
    type: 'woff',
  },
  {
    name: 'Poppins-ExtraBold',
    type: 'woff',
  },
  {
    name: 'Poppins-ExtraBoldItalic',
    type: 'woff',
  },
  {
    name: 'Poppins-ExtraLight',
    type: 'woff',
  },
  {
    name: 'Poppins-ExtraLightItalic',
    type: 'woff',
  },
  {
    name: 'Poppins-Italic',
    type: 'woff',
  },
  {
    name: 'Poppins-Light',
    type: 'woff',
  },
  {
    name: 'Poppins-LightItalic',
    type: 'woff',
  },
  {
    name: 'Poppins-Medium',
    type: 'woff',
  },
  {
    name: 'Poppins-MediumItalic',
    type: 'woff',
  },
  {
    name: 'Poppins-Regular',
    type: 'woff',
  },
  {
    name: 'Poppins-SemiBold',
    type: 'woff',
  },
  {
    name: 'Poppins-SemiBoldItalic',
    type: 'woff',
  },
  {
    name: 'Poppins-Thin',
    type: 'woff',
  },
  {
    name: 'Poppins-ThinItalic',
    type: 'woff',
  },
  {
    name: 'LG_Smart_UI-Bold',
    type: 'woff',
  },
  {
    name: 'LG_Smart_UI-Light',
    type: 'woff',
  },
  {
    name: 'LG_Smart_UI-Regular',
    type: 'woff',
  },
  {
    name: 'LG_Smart_UI-SemiBold',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-Black',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-BlackItalic',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-Bold',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-BoldItalic',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-ExtraBold',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-ExtraBoldItalic',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-Light',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-LightItalic',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-Medium',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-MediumItalic',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-SemiBold',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-SemiBoldItalic',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-Thin',
    type: 'woff',
  },
  {
    name: 'SaudiaSans-ThinItalic',
    type: 'woff',
  },
  {
    name: 'HelveticaNeue',
    type: 'woff',
  },
  {
    name: 'ZeekrText-Regular',
    type: 'woff',
  },
  {
    name: 'ZeekrText-RegularItalic',
    type: 'woff',
  },
  {
    name: 'ZeekrText-Bold',
    type: 'woff',
  },
  {
    name: 'ZeekrText-BoldItalic',
    type: 'woff',
  },
  {
    name: 'ZeekrHeadline-Regular',
    type: 'woff',
  },
  {
    name: 'ZeekrHeadline-RegularItalic',
    type: 'woff',
  },
  {
    name: 'ZeekrHeadline-Medium',
    type: 'woff',
  },
  {
    name: 'ZeekrHeadline-MediumItalic',
    type: 'woff',
  },
  {
    name: 'Druk-Bold',
    type: 'woff',
  },
  {
    name: 'DrukWide-Medium',
    type: 'woff',
  },
  {
    name: 'DrukWide-Super',
    type: 'woff',
  },
  {
    name: 'Gotham-Black',
    type: 'woff',
  },
  {
    name: 'Gotham-Bold',
    type: 'woff',
  },
  {
    name: 'Gotham-Book',
    type: 'woff',
  },
  {
    name: 'Gotham-Medium',
    type: 'woff',
  },
  {
    name: 'jj2_bk',
    type: 'woff',
  },
  {
    name: 'jj2_bd',
    type: 'woff',
  },
  {
    name: 'FormaDJR-display',
    type: 'woff',
  },
  {
    name: 'FormaDJR-micro',
    type: 'woff',
  },
  {
    name: 'FormaDJRUI',
    type: 'woff',
  },
  {
    name: 'EFL1888Pro-Bd',
    type: 'woff',
  },
  {
    name: 'EFL1888Pro-Bk',
    type: 'woff',
  },
  {
    name: 'EFL1888Pro-BkCnd',
    type: 'woff',
  },
  {
    name: 'EFL1888Pro-BkWd',
    type: 'woff',
  },
  {
    name: 'EFL1888Pro-BkxCnd',
    type: 'woff',
  },
  {
    name: 'EFL1888Pro-BkxWd',
    type: 'woff',
  },
  {
    name: 'EFL1888Pro-Md',
    type: 'woff',
  },
  {
    name: 'EFL1888Pro-Rg',
    type: 'woff',
  },
  {
    name: 'EFL1888Pro-xBd',
    type: 'woff',
  },
]

export default allFonts
